import React from "react";
import "../css_files/about.css";
import Markdown from "../components/markdown";
import aboutMd from "../md_files/about/about.md";
import videoExample from "../pictures/IMG_3033.webm";

function About() {
  return (
    <div className="about-page">
      <div>
        <video controls width="600">
          <source
            src={require("../pictures/IMG_3033.webm")}
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </div>

      <Markdown file={aboutMd} />
    </div>
  );
}

export default About;
