import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

function Markdown({ file }) {
  const [content, setContent] = useState("");

  useEffect(() => {
    fetch(file)
      .then((response) => response.text())
      .then((text) => setContent(text));
  }, [file]);

  return <ReactMarkdown>{content}</ReactMarkdown>;
}

export default Markdown;
